.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 15px 15px;

  padding: 32px 20px 20px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 5;
  left: 0;
  right: 0;


  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .search, .hum {

      img {
        width: 30px;
        height: 30px;
      }
    }

  }
}
